<template>
  <div>
    <b-row>
      <!-- Start Table -->
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">All Entries</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="$router.push({name: 'createEntry'})">Add New</b-button>
          </template>
          <template v-slot:body>
            <div v-if="loadTable" class="text-center">
              <b-spinner small type="grow"></b-spinner>
              Loading...
            </div>
            <div v-else>
              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1" v-for="(item, key) in allData" :key="key">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <div block  v-b-toggle="'accordion-' + key" class="p-2 iq-border-radius-10 cursor-pointer d-flex align-items-center justify-content-between toggle_button">
                      <div>
                        <p class="mb-1 text-left">
                          <span class="font-weight-bold">Invoice Number :</span>
                          <span>{{ item.invoice_num }}</span>
                        </p>
                        <p class="m-0 text-left">
                          <span class="font-weight-bold">Created At :</span>
                          <span>{{timeConvert(item.created_at)}}</span></p>
                      </div>
                      <div>
                        <p class="mb-1 text-left">
                          <span class="font-weight-bold">Classification :</span>
                          <span>{{ item.classification.title }}</span>
                        </p>
                        <p class="m-0 text-left">
                          <span class="font-weight-bold">Status :</span>
                          <span>{{ item.status }}</span></p>
                      </div>
                      <div>
                        <p class="mb-1 text-left">
                          <span class="font-weight-bold">Total Debit :</span>
                            <span>{{ splitIntoCreditAndDepit(item.daily_entry).depites.reduce((accumulator, curValue) => accumulator + curValue.amount, 0) }}</span>
                        </p>
                        <p class="m-0 text-left">
                          <span class="font-weight-bold">Total Credit :</span>
                          <span>{{ splitIntoCreditAndDepit(item.daily_entry).credits.reduce((accumulator, curValue) => accumulator + curValue.amount, 0) }}</span></p>
                      </div>
                    </div>
                  </b-card-header>
                  <b-collapse :id="`accordion-${key}`" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <div class="d-flex justify-content-between gap-2">
                        <div class="flex-grow-1">
                          <h5 class="text-center font-weight-bold">Debits</h5>
                          <b-table striped responsive :fields="columns"  :items="splitIntoCreditAndDepit(item.daily_entry).depites" class="mb-0 table-borderless"
                                   sort-icon-left primary-key="name" id="my-table" :tbody-transition-props="transProps">
                            <template v-slot:cell(category)="data">
                              <p> {{ data.item.category.title }}</p>
                            </template>
                            <template v-slot:cell(department)="data">
                              <p> {{ data.item.department.title }}</p>
                            </template>
                            <template v-slot:cell(payment_method)="data">
                              <p> {{ data.item.payment_method.title }}</p>
                            </template>
                            <template v-slot:cell(amount)="data">
                              <p> {{ data.item.amount }} {{data.item.currency.title}}</p>
                            </template>
                          </b-table>
                        </div>
                        <div></div>
                        <div class="flex-grow-1">
                          <h5 class="text-center font-weight-bold">Credits</h5>
                          <b-table striped responsive :fields="columns"  :items="splitIntoCreditAndDepit(item.daily_entry).credits" class="mb-0 table-borderless"
                                   sort-icon-left primary-key="name" id="my-table-debit" :tbody-transition-props="transProps">
                            <template v-slot:cell(category)="data">
                              <p> {{ data.item.category.title }}</p>
                            </template>
                            <template v-slot:cell(department)="data">
                              <p> {{ data.item.department.title }}</p>
                            </template>
                            <template v-slot:cell(payment_method)="data">
                              <p> {{ data.item.payment_method.title }}</p>
                            </template>
                            <template v-slot:cell(amount)="data">
                              <p> {{ data.item.amount }} {{data.item.currency.title}}</p>
                            </template>
                          </b-table>
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
            <b-pagination v-model="pagination.currentPage"
                          class="mt-3"
                          :total-rows="pagination.total"
                          align="right"
                          :per-page="pagination.per_page"
                          aria-controls="my-table"
                          @input="getAllEntries"
            ></b-pagination>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import settingService from '../../services/settings'
export default {
  name: 'createEntry',
  data () {
    return {
      allData: [],
      columns: [
        { label: 'Category', key: 'category', class: 'text-left' },
        { label: 'Department', key: 'department', class: 'text-left' },
        { label: 'Payment Method', key: 'payment_method', class: 'text-left' },
        { label: 'Amount', key: 'amount', class: 'text-left' },
        { label: 'Description', key: 'description', class: 'text-left' },
        { label: 'cheque', key: 'cheque', class: 'text-left' }
      ],
      transProps: {
        // Transition name
        name: 'flip-list'
      },
      pagination: {
        currentPage: 1,
        per_page: 10,
        total: 6
      },
      loadTable: false
    }
  },
  watch: {
  },
  methods: {
    getAllEntries () {
      this.loadTable = true
      settingService.getAllEntries(this.pagination.currentPage).then((res) => {
        this.allData = res.data.data
        this.pagination = res.data.meta
      }).finally(() => {
        this.loadTable = false
      })
    },
    splitIntoCreditAndDepit (arr) {
      const entry = {
        depites: [],
        credits: []
      }
      entry.depites = arr.filter(data => data.type === 'debit')
      entry.credits = arr.filter(data => data.type === 'credit')
      return entry
    }
  },
  mounted () {
    this.getAllEntries()
  },
  created () {
  }
}
</script>
<style>
.accordion .card-body {
  padding: 0.5rem !important;
}
.toggle_button {
  overflow-anchor: none;
  background: transparent;
  border: 1px solid #2d0e54;
  color: #2d0e54 !important;
  font-size: 16px;
}
.accordion > .card > .card-header {
  border: 0 !important;
}
.toggle_button[aria-expanded = true] {
  background: #2d0e54 !important;
  color: #fff !important;
}
.gap-2 {
  gap: 2rem;
}
</style>
